import logo from "../assets/logo-principal-responsive.png";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import youtube from "../assets/youtube.png";
import hgstudio from "../assets/logo-hg.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-details wrapper">
        <img className="logo-footer" src={logo} alt="" />
        <div className="terms-container">
          <div>
            <Link className="terms" to="/terms">
              Términos y condiciones
            </Link>
          </div>
          <div>
            <Link className="terms" to="/questions">
              Preguntas Frecuentes
            </Link>
          </div>
        </div>
        <div className="info-footer-cont">
          <p>Pola 1100, Caba.</p>
          <p>Lunes a sábados de 8 a 18hs.</p>
        </div>
        <div className="redes-container">
          <a href="https://www.facebook.com/cercoelectricoya" target="_blank">
            <img src={facebook} alt="logo facebook" />
          </a>

          <a
            href="https://www.youtube.com/channel/UC91XrZjraWPUDUs7OL9cb9g"
            target="_blank"
          >
            <img src={youtube} alt="logo youtube" />
          </a>
          <a
            href="https://www.instagram.com/cercoelectricoperimetral/"
            target="_blank"
          >
            <img src={instagram} alt="logo instagram" />
          </a>
        </div>
        <a className="logo-hg" href="https://hgstudio.com.ar/" target="_blank">
          <img src={hgstudio} alt="Logo de hg studio" />
          diseño
        </a>
      </div>
    </div>
  );
};

export default Footer;
